module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Streamline Media","short_name":"Streamline Media","start_url":"/","background_color":"#191A1F","theme_color":"#5B40FD","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8b748d17c31dd83431795a708899bf5e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"en","titleTemplate":"%s | Streamline Media","description":"Streamline Media is a premier and dynamic talent agency and a production and distribution company. Our goal is to bring the latest in Esports events to the digital screen by sourcing the very best in talent and production.","openGraph":{"type":"website","locale":"en_IE","site_name":"Streamline Media","description":"Streamline Media is a premier and dynamic talent agency and a production and distribution company. Our goal is to bring the latest in Esports events to the digital screen by sourcing the very best in talent and production."},"twitter":{"cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
